

.instructor-img-wrapper {
    width: 230px;
    height: 230px;
    margin: 0 auto;
    border-radius: 50%;
    padding: 20px;
    border: 10px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    position: relative;
}

.instructor-img-wrapper::after {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid var(--orange);
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transform: rotate(45deg);
    transition: all 400ms ease;
}
.instructor-img-wrapper::before {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid var(--orange);
    opacity: 0.2;
    transform: rotate(45deg);
    transition: all 400ms ease;
}
.instructor-img-wrapper:hover::after {
    transform: rotate(145deg);
}
.instructor-img-wrapper img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

.instructor-img-overlay {
    position: absolute;
    inset: 20px;
    border-radius: 50%;
    background-color: var(--blue);
    opacity: 0;
    transition: all 400ms ease;
}
.instructor-img-wrapper:hover .instructor-img-overlay  {
    opacity: 0.2;
}

.instructor-link {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    transform: translate(-50%,-50%);
    width: 50px ;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 400ms ease;
}
.instructor-link img {
    width: 25px !important;
    height: unset !important;
}

.instructor-img-wrapper:hover .instructor-link {
    opacity: 1;
}
.instructor-slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    row-gap: 50px;
    /* gap: 40px;
    display: grid;
    grid-template-columns: repeat(5,1fr); */
}
.instructor-slide-item {
    flex-direction: 1;
    max-width: 325px;
}
.instructor-slide .slick-slider {
    padding-bottom: 40px;
}
.instructor-slide .slick-slider :is( .slick-prev , .slick-next ) {
    position: absolute;
    bottom : -100px ;
    background-color: var(--white);
    box-shadow: 0 10px 20px rgb(0 0 0 / 7%);
    width: 55px;
    height: 55px;
    border: 0;
    border-radius: 5px;
    outline: none !important;
    opacity: 0.9;
    transition: all 300ms ease;
}
.instructor-slide .slick-slider :is( .slick-prev , .slick-next ) img {
    width: 25px;
}
.instructor-slide .slick-slider :is( .slick-prev , .slick-next )::before{
    display: none;
}
.instructor-slide .slick-slider :is( .slick-prev , .slick-next ):hover{
    opacity: 1;
}
.instructor-slide .slick-slider .slick-prev {
    top : 0 ;
    left: unset;
    right: 0%;
    transform: translateX(-120%) translateY( calc(-100% - 50px)  );
}
.instructor-slide .slick-slider .slick-next {
    top : 0 ;
    right: 0%;
    transform:  translateY( calc(-100% - 50px)  );
}
.instructor-slide .slick-slider .slick-dots {
    bottom: -35px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 15px;
}
.instructor-slide .slick-slider .slick-dots li {
    color: transparent;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}
.instructor-slide .slick-slider .slick-dots li button {
    position: relative;
    overflow: hidden;
    opacity: 0.5;
    font-size: 1px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    color: transparent;
    border: none;
    background-color: var(--blue) ;
    transition: all 200ms ease;
    outline: 2px solid transparent;
    outline-offset: 3px;
}
.instructor-slide .slick-slider .slick-dots .slick-active button {
    opacity: 1;
    outline: 2px solid var(--blue);
}
@media ( min-width : 768px ) and ( max-width : 991.8px ) {
        .instructor-slide {
        gap: 40px;
        justify-content: space-around;
    }
}
@media ( min-width : 576px ) and ( max-width : 767.8px )
{
    .instructors-grid {
        padding-top: 120px !important;
    }
    .instructor-img-wrapper {
        width: 200px;
        height: 200px;
    }
}

@media  ( max-width : 575.8px ) {

    .instructor-slide {
        gap: 40px;
        justify-content: center;
    }
    .instructor-img-wrapper {
    width: 200px;
    height: 200px;
    }

    .instructors-grid {
        padding-top: 120px !important;
    }
    .instructor-slide .slick-slider :is(  .slick-prev , .slick-next ){
        display: none  !important;
    }
    .instructor-slide .slick-slider .slick-dots {
        margin-top: 50px;
        flex-wrap: wrap;
    }
}